import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Plausible from 'plausible-tracker';
import i18n from './localization/i18n';

const { trackPageview } = Plausible({
	domain: 'demo.aftrap.annefrank.org',
	hashMode: true,
});

trackPageview();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

