import * as VideoManager from './VideoManager.js';
import * as HostController from './HostController.js';
import i18next from 'i18next';


export function Initialize(){
	document.addEventListener('video_ended', OnVideoEnd);
	document.addEventListener('video_cuepoint', CuePointHandler);

	Object.values(scenarios).map((scenario) => {
		scenario.videoId = parseInt(i18next.t('pages.video.id'));
		scenario.decisions.map((decision, index) => {
			decision.text = i18next.t(`pages.video.cornervlag.text${index + 1}`);
			decision.videoId = parseInt(i18next.t(`pages.video.cornervlag.id${index + 1}`));
		});
	});

	setTimeout(() => {
		for(let i = 0; i < 4; i++){
			if(document.getElementById('ves-option-' + (i + 1)) != null){
				document.getElementById('ves-option-' + (i + 1)).addEventListener('click', () => {
					document.getElementById('pc-videoend').style.display = 'none';
					document.getElementById('video').classList.remove('d-none');
					MakeDecision((i + 1)); 
	
				});
			}
		}
	}, 1000);
}

//////////////////
// Public functions
////////////////

export function StartScene(tag){
	document.getElementById('intro-sound').pause();
	document.activeElement.blur();
	HostController.WSCall({'type': 'scenario-start'});
	document.getElementById('video').classList.remove('d-none');

	let newScene = scenarios[tag];    
	if(newScene != null){
		VideoManager.Load(newScene.videoId, newScene.decisionTimestamp);
		currentScene = tag;

		// Setup decisions

		SetupDecisions(newScene.decisions);

	}
}

//////
// Scenario Handling
//////////

let currentScene;
let currentDecision;

let scenarioEndEvent = new Event('scenario_end');

let result = false;

function OnVideoEnd(){
	if(result){
		document.dispatchEvent(scenarioEndEvent);
		// document.getElementById('ves').classList.add('active');
		document.getElementById('video').classList.add('d-none');
		HostController.WSCall({'type': 'scenario-end'});
	}
	else{
		//
	}
}

function CuePointHandler(event){
	switch(event.detail.data.event){
	case 'end':
		VideoManager.Pause();
		OnVideoEnd();
		break;
	case 'decisions':
		VideoManager.Pause();
		ShowDecisions();
		HostController.StartDecisions(scenarios[currentScene].decisions);
		break;
	}
}

////////////
// Decisions
///////////

export function MakeDecision(id){
	VideoManager.Load(scenarios[currentScene].decisions[id - 1].videoId), scenarios[currentScene].decisionTimestamp;
	result = true;
	//VideoManager.SetPlaybackPosition(scenarios[currentScene].decisions[id - 1].timestamp);
	//VideoManager.Play();
	HideDecisions();
}

function ShowDecisions(){
	document.getElementById('host-decisions').classList.remove('d-none');
	document.getElementById('decisions-sound').play();
	document.getElementById('host-answercounter').classList.remove('d-none');
	StartTimer();
}

function HideDecisions(){
	HostController.WSCall({'type': 'decision-end'});
	document.getElementById('host-decisions').classList.add('d-none');
}

function SetupDecisions(decisions){
	for(let i = 0; i < decisions.length; i++){
		document.getElementById('decision-' + (i + 1)).innerHTML = decisions[i].text;
		document.getElementById('ves-option-' + (i + 1)).innerHTML = decisions[i].text;
	}
}

////////////
// Timer
//////////////

function StartTimer(){
	document.getElementById('timer-bar-fill').classList.remove('fill-idle');
	document.getElementById('timer-bar-fill').classList.add('fill-decreasing');
	document.getElementById('timer-bar-fill').addEventListener('animationend', TimerCallback);
}

function TimerCallback(){
	HostController.VotingFinished();
}

export function EndTimer(){
	document.getElementById('timer-bar-fill').classList.remove('fill-decreasing');
	document.getElementById('timer-bar-fill').classList.add('fill-idle');

}

///////
// Data
///////

let scenarios = {

	// De cornervlag

	'0' : {
		'videoId': '919200294',
		'decisionTimestamp': 62,
		'decisions': [
			// Decision 1
			{   
				'text': i18next.t('pages.video.cornervlag.text1'),
				'videoId': '919204912',
			},
			// Decision 2
			{   
				'text': i18next.t('pages.video.cornervlag.text2'),
				'videoId': '919204856',
			},
			// Decision 3
			{   
				'text': i18next.t('pages.video.cornervlag.text3'),
				'videoId': '919204883',
			},
			// Decision 4
			{   
				'text': i18next.t('pages.video.cornervlag.text4'),
				'videoId': '919204810',

			},
		],
	},
};

Initialize();