import { useEffect } from 'react';
import styled from 'styled-components';
import * as HostController from '../../js/HostController.js';
import * as VideoManager from '../../js/VideoManager';
import { Logo } from '../components/Logo.jsx';
import { End } from './End.jsx';
import { Landing } from './Landing.jsx';
import { Select } from './Select.jsx';
import { Video } from './Video.jsx';
import { VideoEnd } from './VideoEnd.jsx';
import {Audio} from './Audio.jsx';

export const Host = () => {

	useEffect(() => {
		HostController.Initialize();
		VideoManager.Initialize();
	},[]);

	return (
		<Container>
			<Audio/>
			<Logo/>
			<Video/>
			<Landing/>
			<VideoEnd/>
			<Select/>
			<End/>
		</Container>
	);

};

// styled components

const Container = styled.div`
	min-height: 100%;
	display: flex;
	flex-direction: column;
`;