import styled from 'styled-components';
import ContextProvider from './context/ContextProvider';
import CustomThemeProvider from './style/CustomThemeProvider';
import { Footer } from './views/components/Footer';
import { PageContent } from './views/components/PageContent';
import { DeveloperConsole } from './console/DeveloperConsole';
import { lazy } from 'react';
import i18next from 'i18next';

import {Host} from './views/Host/Host';
import {Player} from './views/Player/Player';

function App() {

	const path = window.location.pathname;

	return (
		<ContextProvider>
			<CustomThemeProvider>
				<DeveloperConsole/>
				<Container>
					<PageContent>
						{ path === '/host' ?
							<Host/> :
							<Player/>
						}
					</PageContent>
					<Footer/>
				</Container>
			</CustomThemeProvider>
		</ContextProvider>
	);
}

// styled components

const Container = styled.div`

	height:100%; width: 100%;

	display: flex;
	flex-direction: column;

`;

export default App;
